exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ballot-ready-js": () => import("./../../../src/pages/ballot-ready.js" /* webpackChunkName: "component---src-pages-ballot-ready-js" */),
  "component---src-pages-civic-glossary-js": () => import("./../../../src/pages/civic-glossary.js" /* webpackChunkName: "component---src-pages-civic-glossary-js" */),
  "component---src-pages-eligibility-check-js": () => import("./../../../src/pages/eligibility-check.js" /* webpackChunkName: "component---src-pages-eligibility-check-js" */),
  "component---src-pages-expanded-issue-js": () => import("./../../../src/pages/expandedIssue.js" /* webpackChunkName: "component---src-pages-expanded-issue-js" */),
  "component---src-pages-get-registered-js": () => import("./../../../src/pages/get-registered.js" /* webpackChunkName: "component---src-pages-get-registered-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issue-based-guides-js": () => import("./../../../src/pages/issue-based-guides.js" /* webpackChunkName: "component---src-pages-issue-based-guides-js" */),
  "component---src-pages-not-elig-js": () => import("./../../../src/pages/not-elig.js" /* webpackChunkName: "component---src-pages-not-elig-js" */),
  "component---src-pages-nyc-election-portal-js": () => import("./../../../src/pages/nyc-election-portal.js" /* webpackChunkName: "component---src-pages-nyc-election-portal-js" */),
  "component---src-pages-nyc-power-map-js": () => import("./../../../src/pages/nyc-power-map.js" /* webpackChunkName: "component---src-pages-nyc-power-map-js" */),
  "component---src-pages-opportunity-board-js": () => import("./../../../src/pages/opportunity-board.js" /* webpackChunkName: "component---src-pages-opportunity-board-js" */),
  "component---src-pages-organization-directory-js": () => import("./../../../src/pages/organization-directory.js" /* webpackChunkName: "component---src-pages-organization-directory-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-power-map-js": () => import("./../../../src/pages/power-map.js" /* webpackChunkName: "component---src-pages-power-map-js" */),
  "component---src-pages-teacher-hub-js": () => import("./../../../src/pages/teacher-hub.js" /* webpackChunkName: "component---src-pages-teacher-hub-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-voting-registration-js": () => import("./../../../src/pages/voting-registration.js" /* webpackChunkName: "component---src-pages-voting-registration-js" */),
  "component---src-pages-voting-registration-u-18-js": () => import("./../../../src/pages/voting-registration-u18.js" /* webpackChunkName: "component---src-pages-voting-registration-u-18-js" */),
  "component---src-templates-lesson-template-js": () => import("./../../../src/templates/lessonTemplate.js" /* webpackChunkName: "component---src-templates-lesson-template-js" */),
  "component---src-templates-rep-template-js": () => import("./../../../src/templates/repTemplate.js" /* webpackChunkName: "component---src-templates-rep-template-js" */)
}

